/* eslint-disable no-nested-ternary */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useState } from "react";
import { ModalConfirm } from "components";
import { Box, IconButton, Switch, Theme, Tooltip } from "@mui/material";
import { IBulkOrganization } from "pages/organizations/models";
import { useFetchAndLoad, useToastMui } from "hooks";
import { bulkOrganizationAPI, useOrganizationListContext } from "pages/organizations/pages";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDeleteOrganizations } from "pages/organizations/hooks";
import { RoleGuard } from "guards";
import { Roles } from "models";

import { useListAndSearchContext } from "context/ListsAndSearchContext";
import BulkOrgMoreOptions from "../../molecules/BulkOrgMoreOptions/BulkOrgMoreOptions";

enum BulkActions {
  ACTIVE = "ACTIVE",
  SUSPEND = "SUSPEND",
  FREC_WEEK = "FREC_WEEK",
  FREC_MOUNTH = "FREC_MOUNTH",
}

interface IBulk {
  is_suspended?: boolean;
  notification_time?: number;
  action?: BulkActions;
}

export default function OrgBulkActions({ onSearch, hasSelectedItems }: { onSearch: Function; hasSelectedItems: boolean }) {
  //** Context */
  const { selected, setSelected } = useOrganizationListContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [bulkObject, setBulkObject] = useState<IBulk>({});
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [activeCheck, setActiveCheck] = useState<boolean>(true);
  //** Hooks */
  const { callEndpoint } = useFetchAndLoad();
  const { handleCreateToast } = useToastMui();
  const { t } = useTranslation();
  const { deleteOrganizations } = useDeleteOrganizations();
  const handleCloseDelete = () => setOpenModalDelete(false);
  //** Function */
  const handleBulk = async () => {
    try {
      const body: IBulkOrganization = { ...bulkObject, item_id: selected };
      setOpenModal(false);
      await callEndpoint(bulkOrganizationAPI({ body }), () => handleCreateToast(t("ALERT-ERROR-UPDATE"), "error"));
      handleCreateToast(t("ALERT-SUCCESS-UPDATE"), "success");
      setSelected([]);
      onSearch();
    } catch (error) {
      console.log(error);
    }
  };
  // Handle  dialog
  const handleClickOpen = (bulkQuery: IBulk) => {
    setOpenModal(true);
    setBulkObject(bulkQuery);
  };
  const handleClose = () => setOpenModal(false);

  const modalTitle =
    bulkObject.action === BulkActions.ACTIVE
      ? `Activate ${selected.length} organizations?`
      : bulkObject.action === BulkActions.SUSPEND
      ? `Suspend ${selected.length} organizations?`
      : `Change the notification frequency of ${selected.length} organizations?`;

  const modalContent =
    bulkObject.action === BulkActions.ACTIVE
      ? t("BULK-STATUS-ORG-POPUP")
      : bulkObject.action === BulkActions.SUSPEND
      ? t("BULK-STATUS-2-ORG-POPUP")
      : bulkObject.action === BulkActions.FREC_WEEK
      ? t("BULK-FREC-ORG-POPUP")
      : t("BULK-FREC-2-ORG-POPUP");

  const handleChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    const action = event.target.checked ? BulkActions.ACTIVE : BulkActions.SUSPEND;
    handleClickOpen({ is_suspended: !event.target.checked, action });
    setActiveCheck(event.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <RoleGuard authorizedRoles={[Roles.superAdmin]}>
        <Tooltip title={t("ICON-DELETE")}>
          <IconButton onClick={() => setOpenModalDelete(true)} disabled={!hasSelectedItems}>
            <DeleteOutlineOutlinedIcon sx={{ fontSize: 22 }} color="error" />
          </IconButton>
        </Tooltip>
      </RoleGuard>
      <Switch checked={activeCheck} onChange={handleChangeActive} disabled={!hasSelectedItems} color="success" />

      <BulkOrgMoreOptions
        hasSelectedItems={hasSelectedItems}
        handleOption1={() => handleClickOpen({ notification_time: 7, action: BulkActions.FREC_WEEK })}
        handleOption2={() => handleClickOpen({ notification_time: 30, action: BulkActions.FREC_MOUNTH })}
      />
      <ModalConfirm
        open={openModal}
        title={modalTitle}
        description={modalContent}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleClose}
        handleConfirm={handleBulk}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
      <ModalConfirm
        open={openModalDelete}
        title={`Delete ${selected.length} organizations?`}
        description={t("ORGANIZATIONS-DELETE-MODAL-CONFIRM-DESCRIPTION")}
        textButtonConfirm={t("BUTTON-ACCEPT")}
        handleClose={handleCloseDelete}
        handleConfirm={() => {
          deleteOrganizations(selected);
          setOpenModalDelete(false);
          onSearch();
        }}
        sxDialog={{ "& .MuiPaper-root": { width: "100%", maxWidth: 650, p: [2, 10] } }}
        sxTitle={{ fontSize: "2rem !important" }}
      />
    </Box>
  );
}
